<template>
    <div class="title-bar">
        <div class="left">
            <Button type="primary" class="return" @click="$emit('back')">
                <i class="nazaio-iconfont nz-arrow-left" />
                返回
            </Button>
            <h2>{{status > 0 ? '编辑图文' : '新建轻页面'}}</h2>
            <h3 v-if="status > 0" v-bind:class="[statusClass]">{{statusText}}</h3>
        </div>
        <div class="right">
            <Button v-if="status != 2" type="primary" class="save-send" @click="$emit('publish')">
                <i class="nazaio-iconfont nz-send" />
                保存并发布
            </Button>
            <Button v-if="status != 2" class="save" @click="$emit('save')">
                <i class="nazaio-iconfont nz-save" />
                保存
            </Button>
            <Button v-if="status == 2" type="error" class="pause" @click="$emit('pause')">
                <i class="nazaio-iconfont nz-stop-circle" />
                暂停发布
            </Button>
            <Button v-if="status > 0" class="btn-preview" @click="$emit('preview')">
                <i class="nazaio-iconfont nz-eye" />
                预览
            </Button>
        </div>
    </div>
</template>
<script>
export default {
    name: "LightPageAddTitleBar",
    props: ["status"],
    computed: {
        statusText() {
            if (this.status == 1) {
                return "草稿箱";
            } else if (this.status == 2) {
                return "已发布";
            } else if (this.status == 3) {
                return "暂停中";
            } else {
                return "未知";
            }
        },
        statusClass() {
            if (this.status == 1) {
                return "status-save";
            } else if (this.status == 2) {
                return "status-publish";
            } else if (this.status == 3) {
                return "status-stop";
            }
            return "";
        }
    }
}
</script>
<style lang="less" scoped>
.title-bar {
    position: absolute;
    height: 48px;
    left: 0;
    top: 0;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 1001;

    .left {
        float: left;
    }

    h2 {
        float: left;
        font-size: 16px;
        color: #0A2A4C;
        font-weight: 400;
        margin-left: 10px;
        height: 48px;
        line-height: 48px;
    }

    h3 {
        float: left;
        font-size: 13px;
        height: 20px;
        line-height: 20px;
        margin-left: 14px;
        margin-top: 14px;
        font-weight: 400;
        width: 56px;
        text-align: center;
        border-left: 1px solid #E8EAEC;
    }

    .status-save {
        color: #8494A6;
    }

    .status-publish {
        color: @fc-main-color;
    }

    .status-stop {
        color: #ED4014;
    }

    .right {
        float: right;
        margin-top: 8px;
    }

    .save-send {
        margin-right: 8px;
    }

    .save {
        margin-right: 8px;
        color: #0C2A4C;
    }

    .pause {
        margin-right: 8px;
    }

    .btn-preview {
        margin-right: 16px;
        color: #0C2A4C;
    }
}
</style>
<style lang="less">
    .return {
        margin: 8px 0 8px 20px;
        float: left;
    }
</style>