<template>
    <div class="property-setting">
        <h1>编辑控件</h1>
        <div
            v-if="question.questionDefinition.properties.indexOf('title') >= 0"
            class="property"
        >
            <h2>题目<span>*</span></h2>
            <div class="item">
                <Input
                    :disabled="status === 2"
                    v-model="title"
                    placeholder="请输入题目"
                    long
                />
            </div>
        </div>
        <div
            v-if="
                question.questionDefinition.properties.indexOf('options') >= 0
            "
            class="property"
        >
            <h2>选项<span>*</span></h2>
            <div class="item">
                <div
                    class="option"
                    v-for="(option, index) in options"
                    :key="index"
                >
                    <Input
                        :disabled="status === 2"
                        v-model="options[index]"
                        placeholder="请输入选项"
                        style="width: 80%"
                    /><button @click="removeOption(index)">
                        <i class="nazaio-iconfont nz-close" />
                    </button>
                </div>
                <div class="add-option" v-if="status !== 2">
                    <button @click="$emit('addoption')">添加选项</button>
                </div>
            </div>
        </div>
        <div
            v-if="question.questionDefinition.properties.indexOf('other') >= 0"
            class="property"
        >
            <Checkbox
                :disabled="status === 2"
                v-model="other"
                style="font-size: 14px; color: #0a2a4c"
                ><span>设置其他选项</span
                ><span style="font-size: 12px; color: #8494a6"
                    >（最多输入30个字符）</span
                ></Checkbox
            >
        </div>
        <div
            v-if="
                question.questionDefinition.properties.indexOf('maxLength') >= 0
            "
            class="property"
        >
            <Checkbox
                :disabled="status === 2"
                v-model="enableLengthLimit"
                style="font-size: 14px; color: #0a2a4c"
            >
                <span>设置字数限制</span
                ><Input
                    :disabled="status === 2"
                    type="number"
                    v-model="maxLength"
                    style="width: 60px"
                /><span>字</span>
            </Checkbox>
        </div>
        <div
            v-if="
                question.questionDefinition.properties.indexOf('required') >= 0
            "
            class="property"
        >
            <Checkbox
                :disabled="status === 2 || !enableRequired"
                v-model="required"
                style="font-size: 14px; color: #0a2a4c"
                ><span>必填</span></Checkbox
            >
        </div>
        <div
            v-if="
                question.questionDefinition.properties.indexOf('smsValidate') >=
                0
            "
            class="property"
        >
            <Checkbox
                :disabled="status === 2"
                v-model="enableSmsValidator"
                style="font-size: 14px; color: #0a2a4c"
                ><span>短信验证</span></Checkbox
            >
        </div>
        <div
            v-if="
                question.questionDefinition.properties.indexOf('smsValidate') >=
                0
            "
            class="property"
        >
            <h2>选择短信平台<span>*</span></h2>
            <div class="item">
                <Select
                    :disabled="status === 2 || !enableSmsValidator"
                    :transfer="true"
                    v-model="smsAccountId"
                    clearable
                    placeholder="请选择短信平台"
                    long
                >
                    <Option
                        v-for="channel in smsAccountList"
                        :value="channel.id"
                        :key="channel.id"
                        >{{ channel.channelName + "-" + channel.phone }}</Option
                    >
                </Select>
            </div>
        </div>
        <div
            v-if="
                question.questionDefinition.properties.indexOf('smsValidate') >=
                0
            "
            class="property"
        >
            <h2>选择短信模板<span>*</span></h2>
            <div class="item">
                <Select
                    :disabled="status === 2 || !enableSmsValidator"
                    :transfer="true"
                    v-model="smsTemplate"
                    clearable
                    placeholder="请选择短信平台"
                    long
                >
                    <Option
                        v-for="(template, index) in smsTemplates"
                        :value="template.tpl_content"
                        :key="index"
                        >{{ template.tpl_content }}</Option
                    >
                </Select>
            </div>
        </div>
        <div
            v-if="question.questionDefinition.properties.indexOf('text') >= 0"
            class="property"
        >
            <h2>按钮文本<span>*</span></h2>
            <div class="item">
                <Input
                    :disabled="status === 2"
                    v-model="text"
                    placeholder="请输入文本"
                    long
                />
            </div>
        </div>
        <div
            v-if="question.questionDefinition.properties.indexOf('color') >= 0"
            class="property"
        >
            <div class="color">
                <h2>文本颜色</h2>
                <div class="con">
                    <ColorPicker
                        :disabled="status === 2"
                        :transfer="true"
                        v-model="color"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="
                question.questionDefinition.properties.indexOf(
                    'backgroundColor'
                ) >= 0
            "
            class="property"
        >
            <div class="color">
                <h2>按钮背景色</h2>
                <div class="con">
                    <ColorPicker
                        :disabled="status === 2"
                        :transfer="true"
                        v-model="backgroundColor"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="
                question.questionDefinition.properties.indexOf(
                    'parameterKey'
                ) >= 0
            "
            class="property"
        >
            <div class="item">
                <h2>参数名<span>*</span></h2>
                <div class="item">
                    <Input
                        :disabled="status === 2"
                        v-model="parameterKey"
                        placeholder="请输入参数名"
                        long
                    />
                </div>
            </div>
        </div>
        <div
            v-if="
                question.questionDefinition.properties.indexOf('display') >= 0
            "
            class="property"
        >
            <Checkbox
                :disabled="status === 2"
                v-model="display"
                style="font-size: 14px; color: #0a2a4c"
                ><span>控件可见</span></Checkbox
            >
        </div>
        <div
            v-if="
                question.questionDefinition.properties.indexOf('commitLogic') >=
                0
            "
            class="property"
        >
            <h2>提交后的默认逻辑<span>*</span></h2>
            <div class="item">
                <RadioGroup
                    v-model="commitLogicType"
                    vertical
                    @on-change="commitLogicValue = ''"
                    style="margin-bottom: 10px"
                >
                    <Radio label="message" :disabled="status === 2">
                        显示文字
                    </Radio>
                    <Radio label="data" :disabled="status === 2">
                        显示提交的数据
                    </Radio>
                    <Radio label="link" :disabled="status === 2">
                        跳转到网页
                    </Radio>
                    <Radio label="lightpage" :disabled="status === 2">
                        跳转到轻页面
                    </Radio>
                </RadioGroup>
                <Input
                    v-if="commitLogicType === 'message'"
                    type="textarea"
                    class="question-property-text"
                    :rows="3"
                    :disabled="status === 2"
                    v-model="commitLogicValue"
                    placeholder="请输入显示文字"
                    long
                />
                <Input
                    v-if="commitLogicType === 'link'"
                    :disabled="status === 2"
                    type="url"
                    v-model="commitLogicValue"
                    placeholder="请输入跳转链接"
                    long
                />
                <Select
                    v-if="commitLogicType === 'lightpage'"
                    :disabled="status === 2"
                    :transfer="true"
                    v-model="commitLogicValue"
                    clearable
                    filterable
                    placeholder="请搜索或选择轻页面"
                    long
                >
                    <Option
                        v-for="lightpage in lightpages"
                        :value="lightpage.id"
                        :key="lightpage.id"
                        >{{ lightpage.title }}</Option
                    >
                </Select>
            </div>
        </div>
        <div
            v-if="question.questionDefinition.properties.indexOf('banner') >= 0"
            class="property carousel-properties"
        >
            <Upload
                :disabled="status == 2"
                action=""
                :before-upload="uploadBanner"
                accept="image/*"
                :format="['jpg', 'jpeg', 'png']"
                :max-size="10240"
                class="header-carousel-upload"
            >
                <div class="upload-btn">
                    <i class="nazaio-iconfont nz-cloud-upload" />
                    <h2>点击上传</h2>
                    <h3>文件类型：jpg、png</h3>
                </div>
            </Upload>
            <ul class="carousel-image-list" v-if="imageList.length > 0">
                <li v-for="(item, index) in imageList" :key="index">
                    <i class="nazaio-iconfont nz-file-text" />
                    <h3>{{ item.name }}</h3>
                    <i
                        @click="imageList.splice(index, 1)"
                        class="nazaio-iconfont nz-delete"
                    />
                </li>
            </ul>
            <div class="remark">
                <i class="nazaio-iconfont nz-info-circle" />
                <p>
                    为保证Banner的显示效果，请上传像素为 720*280
                    大小的图片，最多可上传4张轮播图
                </p>
            </div>
        </div>
        <div
            v-if="
                question.questionDefinition.properties.indexOf('displayFile') >=
                0
            "
            class="property display-file-properties"
        >
            <Upload
                :disabled="status == 2"
                action=""
                :before-upload="uploadFile"
                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.mp4,"
                :format="['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'mp4']"
                :max-size="10240"
                class="header-carousel-upload"
            >
                <div class="upload-btn">
                    <i class="nazaio-iconfont nz-cloud-upload" />
                    <h2>点击上传</h2>
                    <h3>文件类型：Word、Excel、PPT、PDF、mp4</h3>
                </div>
            </Upload>
            <div v-if="fileUrl" class="file-item">
                <i class="nazaio-iconfont nz-file-text" />
                <h3>{{ fileName }}</h3>
            </div>
            <div class="remark">
                <i class="nazaio-iconfont nz-info-circle" />
                <p>上传格式支持Excel、PPT、Word、PDF和MP4格式</p>
            </div>
        </div>
        <template
            v-if="question.questionDefinition.properties.indexOf('map') >= 0"
        >
            <div class="property">
                <h2>标题<span>*</span></h2>
                <div class="item">
                    <Input
                        :disabled="status === 2"
                        v-model="mapTitle"
                        placeholder="请输入标题"
                        long
                    />
                </div>
            </div>
            <div class="property">
                <h2>设置地理位置<span>*</span></h2>
                <div class="item">
                    <Input
                        :disabled="status === 2"
                        v-model="city"
                        placeholder="请输入城市"
                        long
                    />
                </div>
                <div class="item">
                    <Select
                        :disabled="status === 2"
                        v-model="selectPosId"
                        filterable
                        clearable
                        placeholder="请输入地点关键字"
                        :loading="searching"
                        :remote-method="searchKeywords"
                        @on-query-change="queryChange"
                        :default-label="locationInfo.name"
                        @on-change="selectChange"
                        ref="remoteSearch"
                        long
                    >
                        <Option
                            v-for="(option, index) in locationList"
                            :value="option.id"
                            :key="option.id + index"
                            :label="option.name"
                        >
                            <h3 class="option-title">{{ option.name }}</h3>
                            <p class="option-desc">{{ option.address }}</p>
                        </Option>
                    </Select>
                </div>
            </div>
            <div class="property">
                <h2>提示</h2>
                <div class="item">
                    <Input
                        :disabled="status === 2"
                        type="textarea"
                        :rows="4"
                        maxlength="50"
                        show-word-limit
                        v-model="tooltip"
                        placeholder="请输入地址提示信息"
                        long
                    />
                </div>
            </div>
            <div class="property">
                <div class="item">
                    <RadioGroup v-model="mapStyle">
                        <Radio label="map">地图样式</Radio>
                        <Radio style="margin-left: 20px" label="text"
                            >文字样式</Radio
                        >
                    </RadioGroup>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { getYunpianTemplateList } from "@/api/channel";
import { ossCmsUpload } from "@/util/oss";
import { searchKeywords } from "@/api/amap";

export default {
    name: "QuestionProperties",
    props: ["status", "question", "smsAccountList", "lightpages"],
    created() {
        if (this.smsAccountId) {
            this.loadYunpianTemplateList(this.smsAccountId);
            console.log(this.question);
        }
    },
    mounted() {
        if (this.$refs.remoteSearch) {
            this.$refs.remoteSearch.validateOption = () => true;
        }
    },
    data() {
        return {
            title: this.question.title,
            options: this.question.options,
            other: this.question.other,
            required: this.question.required,
            maxLength: this.question.maxLength,
            color: this.question.color,
            backgroundColor: this.question.backgroundColor,
            text: this.question.text,
            enableLengthLimit:
                this.question.maxLength && this.question.maxLength > 0,
            enableSmsValidator:
                this.question.smsAccountId && this.question.smsAccountId > 0,
            smsAccountId: this.question.smsAccountId,
            smsTemplates: undefined,
            smsTemplate: this.question.smsTemplate,
            parameterKey: this.question.parameterKey,
            display: this.question.display,
            commitLogicType: this.question.commitLogicType,
            commitLogicValue: this.question.commitLogicValue,
            imageList: this.question.imageList,
            fileUrl: this.question.fileUrl,
            fileName: this.question.fileName,
            mapTitle: this.question.mapTitle,
            city: this.question.city,
            locationInfo: this.question.locationInfo,
            tooltip: this.question.tooltip,
            mapStyle: this.question.mapStyle,
            locationList: [],
            searching: false,
            selectPosId: this.question.locationInfo
                ? this.question.locationInfo.id
                : "",
        };
    },
    computed: {
        enableRequired() {
            return !this.enableSmsValidator;
        },
    },
    methods: {
        removeOption(index) {
            if (this.options.length > 2) {
                this.$emit("removeOption", index);
                return;
            }
            this.$Message.error("不能少于两个选项！");
        },
        loadYunpianTemplateList(smsAccountId) {
            getYunpianTemplateList(smsAccountId).then((response) => {
                this.smsTemplates = response;
            });
        },
        uploadBanner(file) {
            if (this.imageList.length >= 4) {
                this.$Message.error("最多只能上传4张图片！");
                return;
            }
            ossCmsUpload(file, (result) => {
                this.$Message.success("上传完成！");
                let item = { url: "", name: result.fileName };
                if (result.resourceUrl) {
                    item.url = result.resourceUrl + "/" + result.Key;
                } else {
                    item.url = result.Location;
                }
                this.imageList.push(item);
            });
            return false;
        },
        uploadFile(file) {
            ossCmsUpload(file, (result) => {
                this.$Message.success("上传完成！");
                this.fileName = result.fileName;
                if (result.resourceUrl) {
                    this.fileUrl = result.resourceUrl + "/" + result.Key;
                } else {
                    this.fileUrl = result.Location;
                }
            });
            return false;
        },
        searchKeywords(keywords) {
            this.searching = true;
            searchKeywords(this.city, keywords).then((response) => {
                if (
                    response.status == "1" &&
                    response.pois &&
                    response.pois.length
                ) {
                    response.pois.forEach(poi => {
                        this.locationList.push({
                            id: poi.id,
                            name: poi.name,
                            address: poi.address,
                            location: poi.location
                        });
                    });
                }
                this.searching = false;
            });
        },
        queryChange() {
            this.locationList = [];
        },
        selectChange(val) {
            if (val) {
                let item = this.locationList.find((l) => l.id == val);
                this.locationInfo = {
                    id: item.id,
                    name: item.name,
                    address: item.address,
                    location: item.location,
                };
            } else {
                this.locationInfo = {
                    id: "",
                    name: "",
                    address: "",
                    location: "",
                };
            }
        },
    },
    watch: {
        enableLengthLimit(val) {
            if (!val) {
                this.maxLength = 0;
            }
        },
        enableSmsValidator(val) {
            if (!val) {
                this.smsAccountId = 0;
            } else {
                this.required = true;
            }
        },
        title(val) {
            this.$emit("updateTitle", val);
        },
        options: {
            handler(newValue) {
                this.$emit("updateOptions", newValue);
            },
            deep: true,
        },
        other(val) {
            this.$emit("updateOther", val);
        },
        required(val) {
            this.$emit("updateRequired", val);
        },
        maxLength(val) {
            this.$emit("updateMaxLength", val);
        },
        color(val) {
            this.$emit("updateColor", val);
        },
        backgroundColor(val) {
            this.$emit("updateBackgroundColor", val);
        },
        text(val) {
            this.$emit("updateText", val);
        },
        smsAccountId(val) {
            this.$emit("updateSmsAccountId", val);
            this.smsTemplate = undefined;
            if (!val) {
                this.smsTemplates = undefined;
            } else {
                this.loadYunpianTemplateList(val);
            }
        },
        smsTemplate(val) {
            this.$emit("updateSmsTemplate", val);
        },
        parameterKey(val) {
            this.$emit("updateParameterKey", val);
        },
        display(val) {
            this.$emit("updateDisplay", val);
        },
        commitLogicType(val) {
            this.$emit("updateCommitLogic", {
                commitLogicType: val,
                commitLogicValue: "",
            });
        },
        commitLogicValue(val) {
            this.$emit("updateCommitLogic", {
                commitLogicType: this.commitLogicType,
                commitLogicValue: val,
            });
        },
        imageList(val) {
            this.$emit("updateImageList", val);
        },
        fileUrl(val) {
            this.$emit("updateFileUrl", {
                fileUrl: val,
                fileName: this.fileName,
            });
        },
        mapTitle(val) {
            this.$emit("updateMapTitle", val);
        },
        city(val) {
            this.$emit("updateCity", val);
        },
        locationInfo(val) {
            this.$emit("updateLocationInfo", val);
        },
        tooltip(val) {
            this.$emit("updateTooltip", val);
        },
        mapStyle(val) {
            this.$emit("updateMapStyle", val);
        },
    },
};
</script>
<style lang="less" scoped>
.property-setting {
    padding: 20px;
}

h1 {
    color: #0a2a4c;
    font-size: 16px;
    height: 22px;
    line-height: 22px;
    font-weight: bold;
    margin: 0;
}

.property {
    margin-top: 30px;

    h2 {
        font-size: 14px;
        font-weight: bold;
        height: 20px;
        line-height: 20px;
        color: #0a2a4c;
        margin: 0;

        span {
            color: #ff0000;
            margin-left: 5px;
        }
    }

    .item {
        margin-top: 8px;
    }
}

.option {
    button {
        width: 32px;
        height: 32px;
        border: none;
        outline: none;
        background-color: transparent;
        line-height: 32px;
        font-size: 24px;
        color: #d4dbe1;
        cursor: pointer;
    }
}

.add-option {
    margin-top: 10px;
    button {
        height: 32px;
        outline: none;
        border: none;
        color: #fff;
        background-color: @fc-main-color;
        display: block;
        width: 100%;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
    }
}

.color {
    display: flex;
    justify-content: space-between;

    h2 {
        height: 32px;
        line-height: 32px;
    }
}

.carousel-properties {
    .upload-btn {
        border: 1px dashed #d4dbe1;
        width: 100%;
        cursor: pointer;

        &:hover {
            border-color: @fc-main-color;
        }

        i {
            font-size: 31px;
            width: 32px;
            height: 32px;
            display: block;
            margin: 10px auto 0 auto;
            color: @fc-main-color;
        }

        h2 {
            text-align: center;
            font-size: 14px;
            height: 22px;
            line-height: 22px;
            margin: 10px 0 0 0;
            font-weight: normal;
            color: #395069;
        }

        h3 {
            text-align: center;
            font-size: 12px;
            color: #8494a6;
            margin: 0 0 16px 0;
            font-weight: normal;
            height: 18px;
            line-height: 18px;
        }
    }

    .carousel-image-list {
        list-style: none;
        padding: 0;
        margin-top: 8px;

        li {
            position: relative;
            overflow: hidden;
            height: 28px;
            line-height: 28px;

            &:hover {
                background-color: #f8f8f8;
            }

            .nz-file-text {
                color: #8494a6;
                float: left;
            }

            h3 {
                font-weight: normal;
                margin: 0;
                text-align: left;
                color: #395069;
                position: absolute;
                left: 26px;
                right: 26px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
            }

            .nz-delete {
                color: #8494a6;
                float: right;
                margin-right: 5px;

                &:hover {
                    color: #ed4014;
                }
            }
        }
    }

    .remark {
        display: flex;
        margin-top: 5px;

        i {
            color: @fc-main-color;
            width: 16px;
        }

        p {
            margin: 3px 0 0 10px;
            font-size: 12px;
            color: #8494a6;
            line-height: 18px;
        }
    }
}

.display-file-properties {
    .upload-btn {
        border: 1px dashed #d4dbe1;
        width: 100%;
        cursor: pointer;

        &:hover {
            border-color: @fc-main-color;
        }

        i {
            font-size: 31px;
            width: 32px;
            height: 32px;
            display: block;
            margin: 10px auto 0 auto;
            color: @fc-main-color;
        }

        h2 {
            text-align: center;
            font-size: 14px;
            height: 22px;
            line-height: 22px;
            margin: 10px 0 0 0;
            font-weight: normal;
            color: #395069;
        }

        h3 {
            text-align: center;
            font-size: 12px;
            color: #8494a6;
            margin: 0 0 16px 0;
            font-weight: normal;
            height: 18px;
            line-height: 18px;
        }
    }

    .file-item {
        margin-top: 8px;
        position: relative;
        overflow: hidden;
        height: 28px;
        line-height: 28px;

        &:hover {
            background-color: #f8f8f8;
        }

        .nz-file-text {
            color: #8494a6;
            float: left;
        }

        h3 {
            font-weight: normal;
            margin: 0;
            text-align: left;
            color: #395069;
            position: absolute;
            left: 26px;
            right: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
        }
    }

    .remark {
        display: flex;
        margin-top: 5px;

        i {
            color: @fc-main-color;
            width: 16px;
        }

        p {
            margin: 3px 0 0 10px;
            font-size: 12px;
            color: #8494a6;
            line-height: 18px;
        }
    }
}

.option-title {
    font-size: 14px;
    margin: 0;
    line-height: 20px;
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal;
}

.option-desc {
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal;
    font-size: 12px;
    line-height: 18px;
    color: #8494a6;
}
</style>