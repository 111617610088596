<template>
    <div class="question-item">
        <Carousel
            :autoplay="imageList.length > 1"
            loop
            :autoplay-speed="3000"
            v-if="imageList.length > 0"
        >
            <CarouselItem v-for="(image, index) in imageList" :key="index">
                <div class="image-item" :style="{ height: height }">
                    <img :src="image.url" />
                </div>
            </CarouselItem>
        </Carousel>
        <div v-else class="image-placeholder" :style="{ height: height }">
            <i class="nazaio-iconfont nz-image-fill"></i>
        </div>
    </div>
</template>
<script>
export default {
    name: "Banner",
    props: {
        imageList: {
            type: Array,
            default() {
                return [];
            }
        },
        ratio: {
            type: Number,
            default: 2.571
        }
    },
    mounted() {
        this.width = this.$el.offsetWidth;
    },
    data() {
        return {
            width: 0,
        };
    },
    computed: {
        height() {
            return this.width / this.ratio + "px";
        },
    },
};
</script>
<style lang="less" scoped>
.image-item {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        min-width: 100%;
        min-height: 100%;
    }
}

.image-placeholder {
    background-color: #e8eaec;
    position: relative;

    i {
        display: block;
        font-size: 124px;
        text-align: center;
        position: absolute;
        width: 100%;
        top: 50%;
        margin-top: -93px;
        height: 50px;
        color: #C6C9CF;
    }
}
</style>