import { outRequest } from '@/util/request';
// import qs from "qs";

export function getIpInfo() {
    return outRequest({
        url: 'https://restapi.amap.com/v3/ip',
        method: 'get',
        params: { key: process.env.VUE_APP_AMAP_KEY }
    });
}

export function getDistrict(adcode) {
    return outRequest({
        url: 'https://restapi.amap.com/v3/config/district',
        method: 'get',
        params: { key: process.env.VUE_APP_AMAP_KEY, keywords: adcode }
    });
}

export function searchKeywords(city, keywords) {
    return outRequest({
        url: 'https://restapi.amap.com/v3/place/text',
        method: 'get',
        params: { key: process.env.VUE_APP_AMAP_KEY, city, keywords }
    });
}