<template>
    <div>
        <TitleBar
            :status="pageInfo.status"
            v-on:back="back"
            v-on:publish="publish"
            v-on:pause="pause"
            v-on:save="save"
            v-on:preview="preview"
        ></TitleBar>
        <div class="main-container">
            <Row>
                <Col span="5">
                    <Tabs type="card">
                        <TabPane label="封面图">
                            <div class="panel-wrapper">
                                <h2 class="title" required>封面标题</h2>
                                <Input :disabled="pageInfo.status == 2" v-model="pageInfo.title" placeholder="请输入封面标题" />
                                <h2 class="title">封面描述</h2>
                                <Input
                                    :disabled="pageInfo.status == 2"
                                    class="main-description"
                                    v-model="pageInfo.description"
                                    :autosize="{ maxRows: 3, minRows: 3 }"
                                    type="textarea"
                                    placeholder="请输入封面描述"
                                />
                                <h2 class="title" required>封面图</h2>
                                <Upload
                                    :disabled="pageInfo.status == 2"
                                    type="select"
                                    action=""
                                    :before-upload="uploadCoverImg"
                                    :format="['jpg', 'jpeg', 'png']"
                                    :max-size="10240"
                                >
                                    <div
                                        class="cover-img-upload"
                                        :class="{
                                            'has-image': pageInfo.coverImg,
                                        }"
                                    >
                                        <template v-if="pageInfo.coverImg">
                                            <img :src="pageInfo.coverImg" style="display: block; width: 100%; height: 100%" />
                                        </template>
                                        <template v-else>
                                            <Icon type="ios-cloud-upload" size="60" style="color: #8494a6; margin-top: 20px"></Icon>
                                        </template>
                                    </div>
                                </Upload>
                                <p>建议大小为200*200像素，不大于10Mb</p>
                            </div>
                            <div class="preview-box">
                                <div class="mock-tab">封面预览</div>
                                <div class="preview">
                                    <h2>{{ pageInfo.title || "封面标题" }}</h2>
                                    <div class="detail">
                                        <p class="description">
                                            {{ pageInfo.description || "封面描述" }}
                                        </p>
                                        <div
                                            class="cover-img"
                                            :class="{
                                                grey: !pageInfo.coverImg,
                                            }"
                                        >
                                            <img v-if="pageInfo.coverImg" v-bind:src="pageInfo.coverImg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane label="展示项">
                            <div
                                class="panel-wrapper"
                                :style="{
                                    maxHeight: pannelWrapperHeight + 'px',
                                }"
                            >
                                <h2 class="title">展示控件</h2>
                                <ul class="questions">
                                    <li v-for="(question, index) in displayQuestions" :key="index" @click="insertQuestion(question)">
                                        <QuestionIcon v-bind:icon="question.icon" v-bind:name="question.name" />
                                    </li>
                                </ul>
                                <h2 class="title">背景设置</h2>
                                <div class="background-setting">
                                    <div class="radio-group">
                                        <RadioGroup v-model="pageInfo.style.backgroundType" vertical>
                                            <Radio :disabled="pageInfo.status == 2" style="margin-bottom: 10px" label="color"
                                                ><span>背景色</span></Radio
                                            >
                                            <Radio :disabled="pageInfo.status == 2" label="image"><span>背景图片</span></Radio>
                                        </RadioGroup>
                                    </div>
                                    <div class="bg-val">
                                        <div style="margin-bottom: 10px; text-align: right">
                                            <ColorPicker
                                                :disabled="pageInfo.status == 2"
                                                :transfer="true"
                                                v-model="pageInfo.style.backgroundColor"
                                            />
                                        </div>
                                        <div>
                                            <Tooltip
                                                placement="top"
                                                :transfer="true"
                                                max-width="200"
                                                content="可上传png/jpg格式的图片，建议750*1334像素"
                                                :delay="1000"
                                            >
                                                <Upload
                                                    :disabled="pageInfo.status == 2"
                                                    action=""
                                                    :before-upload="uploadBackgroundImg"
                                                    accept="image/*"
                                                    :format="['jpg', 'jpeg', 'png']"
                                                    :max-size="10240"
                                                >
                                                    <button class="upload-bg">
                                                        <i class="nazaio-iconfont nz-upload" />
                                                        上传图片
                                                    </button>
                                                </Upload>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div class="fill-setting-item">
                                    <h2 class="title">启用页眉轮播图</h2>
                                    <div class="item-switch">
                                        <i-switch :disabled="pageInfo.status == 2" v-model="pageInfo.style.enableHeaderCarousel">
                                            <Icon type="md-checkmark" slot="open"></Icon>
                                            <Icon type="md-close" slot="close"></Icon>
                                        </i-switch>
                                    </div>
                                </div>
                                <div class="header-carousel" v-if="pageInfo.style.enableHeaderCarousel">
                                    <Upload
                                        :disabled="pageInfo.status == 2"
                                        action=""
                                        :before-upload="uploadHeaderCarousel"
                                        accept="image/*"
                                        :format="['jpg', 'jpeg', 'png']"
                                        :max-size="10240"
                                        class="header-carousel-upload"
                                    >
                                        <div class="upload-btn">
                                            <i class="nazaio-iconfont nz-cloud-upload" />
                                            <h2>点击上传</h2>
                                            <h3>文件类型：jpg、png</h3>
                                        </div>
                                    </Upload>
                                    <ul class="carousel-image-list" v-if="pageInfo.style.headerCarouselImages.length > 0">
                                        <li v-for="(item, index) in pageInfo.style.headerCarouselImages" :key="index">
                                            <i class="nazaio-iconfont nz-file-text" />
                                            <h3>{{ item.name }}</h3>
                                            <i
                                                @click="pageInfo.style.headerCarouselImages.splice(index, 1)"
                                                class="nazaio-iconfont nz-delete"
                                            />
                                        </li>
                                    </ul>
                                    <div class="remark">
                                        <i class="nazaio-iconfont nz-info-circle" />
                                        <p>为保证轮播图的显示效果，请上传像素为 750*386 大小的图片，最多可上传4张轮播图</p>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane label="表单">
                            <div
                                class="panel-wrapper"
                                :style="{
                                    maxHeight: pannelWrapperHeight + 'px',
                                }"
                            >
                                <h2 class="title">基础控件</h2>
                                <ul class="questions">
                                    <li v-for="(question, index) in baseQuestions" :key="index" @click="insertQuestion(question)">
                                        <QuestionIcon v-bind:icon="question.icon" v-bind:name="question.name" />
                                    </li>
                                </ul>
                                <h2 class="title">题目控件</h2>
                                <ul class="questions">
                                    <li v-for="(question, index) in normalQuestions" :key="index" @click="insertQuestion(question)">
                                        <QuestionIcon v-bind:icon="question.icon" v-bind:name="question.name" />
                                    </li>
                                </ul>
                                <div class="fill-setting-item">
                                    <h2 class="title">是否设置“提交”按钮</h2>
                                    <div class="item-switch">
                                        <i-switch :disabled="pageInfo.status == 2" v-model="enableCommitButton" @on-change="enableCommit">
                                            <Icon type="md-checkmark" slot="open"></Icon>
                                            <Icon type="md-close" slot="close"></Icon>
                                        </i-switch>
                                    </div>
                                </div>
                                <h2 class="title">表单颜色设置</h2>
                                <div class="form-setting-item">
                                    <h2 class="title">题目文本颜色</h2>
                                    <div class="item-color">
                                        <ColorPicker
                                            :disabled="pageInfo.status == 2"
                                            :transfer="true"
                                            v-model="pageInfo.style.questionColor"
                                        />
                                    </div>
                                </div>
                                <div class="form-setting-item">
                                    <h2 class="title">选项文本颜色</h2>
                                    <div class="item-color">
                                        <ColorPicker
                                            :disabled="pageInfo.status == 2"
                                            :transfer="true"
                                            v-model="pageInfo.style.optionColor"
                                        />
                                    </div>
                                </div>
                                <div class="form-setting-item">
                                    <h2 class="title">表单背景色</h2>
                                    <div class="item-color">
                                        <ColorPicker
                                            :disabled="pageInfo.status == 2"
                                            :transfer="true"
                                            v-model="pageInfo.style.formBgColor"
                                            alpha
                                        />
                                    </div>
                                </div>
                                <h2 class="title">收集填写者微信信息</h2>
                                <div class="official-account-setting">
                                    <button :disabled="pageInfo.status == 2" @click="ifCollect = true" :class="{ active: ifCollect }">
                                        收集用户信息
                                    </button>
                                    <button :disabled="pageInfo.status == 2" @click="ifCollect = false" :class="{ active: !ifCollect }">
                                        不收集用户信息
                                    </button>
                                </div>
                                <template v-if="ifCollect">
                                    <div class="fill-setting-item">
                                        <h2 class="title">是否仅在微信环境打开</h2>
                                        <div class="item-switch">
                                            <i-switch :disabled="pageInfo.status == 2" v-model="pageInfo.onlyWechatEnv">
                                                <Icon type="md-checkmark" slot="open"></Icon>
                                                <Icon type="md-close" slot="close"></Icon>
                                            </i-switch>
                                        </div>
                                    </div>
                                    <h3 class="title">获取填写信息</h3>
                                    <RadioGroup v-model="pageInfo.authorizeType">
                                        <Radio :disabled="pageInfo.status == 2" :label="1"><span>静默获取填写者微信OpenID</span></Radio>
                                        <p class="auth-desc">（无需确认）</p>
                                        <Radio :disabled="pageInfo.status == 2" :label="2" style="margin-top: 10px"
                                            ><span>获取填写者微信个人信息</span></Radio
                                        >
                                        <p class="auth-desc">（需授权确认）</p>
                                    </RadioGroup>
                                    <h3 class="title">请选择微信公众账号</h3>
                                    <div class="official-account-select">
                                        <img src="../../assets/wechat-circle.png" />
                                        <div class="select">
                                            <Select
                                                :disabled="pageInfo.status == 2"
                                                v-model="pageInfo.officialAccountId"
                                                clearable
                                                placeholder="请选择公众号"
                                                style="width: 150px"
                                                :transfer="true"
                                            >
                                                <Option v-for="acc in officialAccounts" :value="acc.id" :key="acc.id">{{
                                                    acc.nickname
                                                }}</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </TabPane>
                    </Tabs>
                </Col>
                <Col span="12" offset="1">
                    <div class="form-body" :style="{ height: this.formBodyHeight + 'px' }" ref="formBody">
                        <div v-if="pageInfo.style.enableHeaderCarousel" class="header-carousel-preview">
                            <Banner :imageList="pageInfo.style.headerCarouselImages" :ratio="1.943"></Banner>
                        </div>
                        <div class="question-content-wrapper" :style="{ background: this.mainBackground }">
                            <div
                                class="question-content"
                                :class="{
                                    'image-bg': pageInfo.backgroundType == 'image',
                                }"
                            >
                                <div class="title">
                                    <Input
                                        type="textarea"
                                        size="large"
                                        :disabled="pageInfo.status == 2"
                                        :autosize="true"
                                        class="light-page-title"
                                        v-model="pageInfo.pageTitle"
                                        placeholder="未命名标题"
                                    />
                                </div>
                                <ul
                                    class="preview-questions"
                                    ref="previewQuestions"
                                    :style="{
                                        backgroundColor: pageInfo.style.formBgColor,
                                    }"
                                >
                                    <li
                                        class="drag-item"
                                        @mousedown="mousedown"
                                        v-for="question in pageInfo.questions"
                                        :key="question.id"
                                        :orderId="question.id"
                                        @click="clickQuestion(question, $event)"
                                        :class="{
                                            focus: focusQuestion === question,
                                        }"
                                    >
                                        <template v-if="question.type === 'rich-text'">
                                            <component
                                                :is="question.questionDefinition.component"
                                                v-bind="question"
                                                v-on:updateRichText="updateRichText(question.id, $event)"
                                                v-on:editDone="question.editable = false"
                                                :key="question.id"
                                                :style="pageInfo.style"
                                            ></component>
                                            <button v-if="pageInfo.status !== 2" class="remove" @click.stop="removeQuestion(question)">
                                                <i class="nazaio-iconfont nz-delete" />
                                            </button>
                                            <button v-if="pageInfo.status !== 2" class="edit" @click="question.editable = true">
                                                <i class="nazaio-iconfont nz-edit-square" />
                                            </button>
                                        </template>
                                        <template v-else>
                                            <component
                                                :is="question.questionDefinition.component"
                                                v-bind="question"
                                                :key="question.id"
                                                :pageInfo="pageInfo"
                                            ></component>
                                            <button v-if="pageInfo.status !== 2" class="remove" @click.stop="removeQuestion(question)">
                                                <i class="nazaio-iconfont nz-delete" />
                                            </button>
                                        </template>
                                    </li>
                                </ul>
                                <div v-if="showGuidePanel" class="guide-panel">
                                    <img src="../../assets/line.png" />
                                    <h3>请从左侧“展示项”及“填写项”点击添加题目内容</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span="5" offset="1">
                    <div class="property-panel" :style="{ height: propertyPanelHeight + 'px' }">
                        <Drawer
                            :closable="true"
                            v-model="showProperty"
                            width="100"
                            :mask="false"
                            :inner="true"
                            :transfer="false"
                            :scrollable="true"
                        >
                            <QuestionProperties
                                v-if="focusQuestion"
                                v-bind:status="pageInfo.status"
                                v-bind:question="focusQuestion"
                                v-bind:smsAccountList="smsAccountList"
                                v-bind:lightpages="lightpages"
                                :key="focusQuestion.id"
                                v-on:addoption="addOption"
                                v-on:removeOption="removeOption"
                                v-on:updateTitle="updateTitle"
                                v-on:updateOther="updateOther"
                                v-on:updateRequired="updateRequired"
                                v-on:updateMaxLength="updateMaxLength"
                                v-on:updateColor="updateColor"
                                v-on:updateBackgroundColor="updateBackgroundColor"
                                v-on:updateText="updateText"
                                v-on:updateSmsAccountId="updateSmsAccountId"
                                v-on:updateSmsTemplate="updateSmsTemplate"
                                v-on:updateParameterKey="updateParameterKey"
                                v-on:updateDisplay="updateDisplay"
                                v-on:updateCommitLogic="updateCommitLogic"
                                v-on:updateImageList="updateImageList"
                                v-on:updateFileUrl="updateFileUrl"
                                v-on:updateMapTitle="updateMapTitle"
                                v-on:updateCity="updateCity"
                                v-on:updateLocationInfo="updateLocationInfo"
                                v-on:updateTooltip="updateTooltip"
                                v-on:updateMapStyle="updateMapStyle"
                            ></QuestionProperties>
                        </Drawer>
                    </div>
                </Col>
            </Row>
            <Modal v-model="modal" title="地址二维码" class="qrcode-detail">
                <h2>图文地址</h2>
                <div class="input">
                    <Input long v-model="pageUrl" readonly />
                </div>
                <h2>二维码</h2>
                <div id="qrcode" class="qrcode-img" ref="qrcode"></div>
            </Modal>
            <Modal v-model="modal2" title="地址二维码" class="qrcode-detail">
                <h2>预览轻页面地址<span style="font-size: 12px; color: #8494a6; margin-left: 10px">（预览连接不会收取任何数据）</span></h2>
                <div class="input">
                    <Input long v-model="pageUrl2" readonly />
                </div>
                <h2>二维码</h2>
                <div id="qrcode2" class="qrcode-img" ref="qrcode2"></div>
            </Modal>
        </div>
    </div>
</template>
<script>
import TitleBar from "./components/TitleBar";
import { displayQuestions, normalQuestions, baseQuestions, otherQuestions } from "@/components/questions/";
import QuestionIcon from "@/components/questions/QuestionIcon";
import QuestionProperties from "@/components/questions/QuestionProperties";
import Banner from "@/components/questions/display/Banner";
import { getOfficialAccountList, find, save, saveAndPublish, getPageUrl, getPreviewUrl, pause, getList } from "@/api/lightpage";
import { getSmsAccountList } from "@/api/channel";
import QRCode from "qrcodejs2";
// import {cosBackendUpload} from "@/util/cos";
import { ossCmsUpload } from "@/util/oss";
// import "@/util/ueditor-upload-wrapper";

export default {
    name: "LightPage",
    components: {
        TitleBar,
        QuestionIcon,
        QuestionProperties,
        Banner,
    },
    created() {
        this.getOfficialAccounts();
        this.loadSmsAccount();
        this.loadLightpages();
        if (this.$route.params.id) {
            find(this.$route.params.id).then((response) => {
                this.pageInfo.id = this.$route.params.id;
                this.pageInfo.title = response.title;
                this.pageInfo.description = response.description;
                this.pageInfo.coverImg = response.coverImg;
                if (response.style) {
                    this.pageInfo.style = JSON.parse(response.style);
                }
                this.pageInfo.officialAccountId = response.officialAccountId;
                this.pageInfo.authorizeType = response.authorizeType;
                this.pageInfo.onlyWechatEnv = response.onlyWechatEnv;
                this.pageInfo.pageTitle = response.pageTitle;
                let questions = JSON.parse(response.template);
                let allQuestions = {};
                Object.assign(allQuestions, displayQuestions, normalQuestions, baseQuestions, otherQuestions);
                questions.forEach((q) => {
                    if (Object.prototype.hasOwnProperty.call(allQuestions, q.type)) {
                        q.questionDefinition = allQuestions[q.type];
                    }
                    if (q.type === "commit-button") {
                        this.enableCommitButton = true;
                    }
                });
                this.pageInfo.questions = questions;

                if (this.pageInfo.officialAccountId > 0) {
                    this.ifCollect = true;
                } else {
                    this.ifCollect = false;
                }
                this.pageInfo.status = response.status;
                this.watchData();
            });
        } else {
            this.insertQuestion(this.displayQuestions["rich-text"]);
            this.pageInfo.questions[this.pageInfo.questions.length - 1].content =
                "为了给您提供更好的服务，希望您能抽出几分钟时间，将您的感受和建议告诉我们，我们非常重视每位用户的宝贵意见，期待您的参与！现在我们就马上开始吧！";
            this.showGuidePanel = true;
            this.watchData();
        }
        document.body.addEventListener("mousemove", this.mousemove);
        document.body.addEventListener("mouseup", this.mouseup);
    },
    beforeRouteLeave(to, from, next) {
        if (this.changed) {
            this.$Modal.confirm({
                title: "确定返回上一页吗？",
                content: "<p>您还没有保存编辑，是否确定返回到上一页？</p>",
                onCancel: () => {
                    next(false);
                },
                onOk: () => {
                    next();
                },
            });
        } else {
            next();
        }
    },
    data() {
        return {
            displayQuestions: displayQuestions,
            normalQuestions: normalQuestions,
            baseQuestions: baseQuestions,
            changed: false,
            pageInfo: {
                status: 0,
                id: 0,
                title: "",
                description: "",
                coverImg: "",
                style: {
                    backgroundType: "color",
                    backgroundColor: "#ffffff",
                    backgroundImage: "",
                    questionColor: "#0a2a4c",
                    optionColor: "#515a6e",
                    formBgColor: "",
                    enableHeaderCarousel: false,
                    headerCarouselImages: [],
                },
                officialAccountId: 0,
                authorizeType: 1,
                onlyWechatEnv: false,
                pageTitle: "",
                questions: [],
            },
            enableCommitButton: false,
            ifCollect: true,
            officialAccounts: null,
            smsAccountList: null,
            lightpages: null,
            focusQuestion: null,
            showProperty: false,
            dragingElement: null,
            dragingWrapper: null,
            dragingStartOffset: {
                x: 0,
                y: 0,
                originX: 0,
                originY: 0,
            },
            modal: false,
            modal2: false,
            pageUrl: "",
            pageUrl2: "",
            showGuidePanel: false,
        };
    },
    computed: {
        pannelWrapperHeight() {
            return window.innerHeight - 120;
        },
        formBodyHeight() {
            return window.innerHeight - 88;
        },
        propertyPanelHeight() {
            return window.innerHeight - 48;
        },
        mainBackground() {
            if (this.pageInfo.style.backgroundType == "color") {
                return this.pageInfo.style.backgroundColor;
            } else {
                if (!this.pageInfo.style.backgroundImage) {
                    return "#fff";
                }
                return "center center url('" + this.pageInfo.style.backgroundImage + "') no-repeat";
            }
        },
    },
    watch: {
        ifCollect(val) {
            if (!val) {
                this.pageInfo.authorizeType = 0;
                this.pageInfo.officialAccountId = 0;
            } else {
                this.pageInfo.authorizeType = 1;
            }
        },
    },
    methods: {
        watchData() {
            this.$watch("pageInfo", {
                handler: () => {
                    this.changed = true;
                },
                deep: true,
            });
        },
        uploadCoverImg(file) {
            ossCmsUpload(file, (result) => {
                this.$Message.success("上传完成！");
                if (result.resourceUrl) {
                    this.pageInfo.coverImg = result.resourceUrl + "/" + result.Key;
                } else {
                    this.pageInfo.coverImg = result.Location;
                }
            });
            return false;
        },
        uploadBackgroundImg(file) {
            ossCmsUpload(file, (result) => {
                this.$Message.success("上传完成！");
                if (result.resourceUrl) {
                    this.pageInfo.style.backgroundImage = result.resourceUrl + "/" + result.Key;
                } else {
                    this.pageInfo.style.backgroundImage = result.Location;
                }
            });
            return false;
        },
        uploadHeaderCarousel(file) {
            if (this.pageInfo.style.headerCarouselImages.length >= 4) {
                this.$Message.error("最多只能上传4张图片！");
                return;
            }
            ossCmsUpload(file, (result) => {
                this.$Message.success("上传完成！");
                let item = { url: "", name: result.fileName };
                if (result.resourceUrl) {
                    item.url = result.resourceUrl + "/" + result.Key;
                } else {
                    item.url = result.Location;
                }
                this.pageInfo.style.headerCarouselImages.push(item);
            });
            return false;
        },
        enableCommit(enable) {
            if (enable) {
                if (this.pageInfo.questions.findIndex((q) => q.type === "commit-button") < 0) {
                    this.insertQuestion(otherQuestions["commit-button"]);
                }
            } else {
                this.pageInfo.questions.splice(
                    this.pageInfo.questions.findIndex((q) => q.type === "commit-button"),
                    1
                );
            }
        },
        back() {
            this.$router.go(-1);
        },
        validate() {
            if (!this.pageInfo.title) {
                this.$Message.error("请填写标题！");
                return false;
            }
            if (!this.pageInfo.coverImg) {
                this.$Message.error("请上传封面图！");
                return false;
            }
            if (this.pageInfo.style.backgroundType == "image" && !this.pageInfo.style.backgroundImage) {
                this.$Message.error("请上传背景图片！");
                return false;
            }
            if (this.pageInfo.style.enableHeaderCarousel) {
                if (!this.pageInfo.style.headerCarouselImages || !this.pageInfo.style.headerCarouselImages.length) {
                    this.$Message.error("请上传页眉轮播图！");
                    return false;
                }
            }
            if (this.pageInfo.officialAccountId > 0 && !this.pageInfo.authorizeType > 0) {
                this.$Message.error("请选择微信授权方式！");
                return false;
            }
            if (this.ifCollect && !this.pageInfo.officialAccountId) {
                this.$Message.error("请选择微信公众账号！");
                return false;
            }
            if (!this.pageInfo.pageTitle) {
                this.$Message.error("请填写轻页面标题！");
                return false;
            }

            for (let i = 0; i < this.pageInfo.questions.length; i++) {
                if (
                    this.pageInfo.questions[i].type == "single-choice" ||
                    this.pageInfo.questions[i].type == "multiple-choice" ||
                    this.pageInfo.questions[i].type == "dropdown-choice"
                ) {
                    for (let j = 0; j < this.pageInfo.questions[i].options.length; j++) {
                        if (this.pageInfo.questions[i].options[j] == null || this.pageInfo.questions[i].options[j].trim() == "") {
                            this.$Message.error("【" + this.pageInfo.questions[i].title + "】选项不能为空！");
                            return false;
                        }
                    }
                }

                if (this.pageInfo.questions[i].type === "mobile") {
                    if (this.pageInfo.questions[i].smsAccountId > 0) {
                        if (!this.pageInfo.questions[i].smsTemplate) {
                            this.$Message.error("请选择短信模板！");
                            return false;
                        }
                        if (this.pageInfo.questions[i].smsTemplate.match(/#/gi).length !== 2) {
                            this.$Message.error("只能选择单参数短信模板！");
                            return false;
                        }
                    }
                }

                if (this.pageInfo.questions[i].type === "commit-button") {
                    if (this.pageInfo.questions[i].commitLogicType !== "data" && !this.pageInfo.questions[i].commitLogicValue) {
                        this.$Message.error("请填选提交后的默认逻辑！");
                        return false;
                    }
                }

                if (this.pageInfo.questions[i].type === "map") {
                    if (!this.pageInfo.questions[i].mapTitle) {
                        this.$Message.error("请填写地理位置标题！");
                        return false;
                    }
                    if (!this.pageInfo.questions[i].locationInfo || !this.pageInfo.questions[i].locationInfo.id) {
                        this.$Message.error("请填选地理位置！");
                        return false;
                    }
                }
            }

            return true;
        },
        publish() {
            if (!this.validate()) {
                return;
            }
            let hasFillControl = false;
            let hasCommitButton = false;
            this.pageInfo.questions.forEach((q) => {
                if (q.type != "rich-text" && q.type != "commit-button") {
                    hasFillControl = true;
                }
                if (q.type == "commit-button") {
                    hasCommitButton = true;
                }
            });
            let confirmMsg = "确定保存并发布图文轻页面吗？";
            if (hasFillControl && !hasCommitButton) {
                confirmMsg = "您尚未添加提交按钮，确定保存并发布图文轻页面吗？";
            }
            this.$Modal.confirm({
                title: confirmMsg,
                content: "<p>发布后表单控件不可再次编辑，如需编辑其他信息，请暂停后进行编辑，是否确认发布？</p>",
                width: 500,
                onOk: () => {
                    var params = JSON.parse(JSON.stringify(this.pageInfo));
                    params.questions.forEach((q) => delete q.questionDefinition);
                    params.template = JSON.stringify(params.questions);
                    delete params.questions;
                    params.style = JSON.stringify(params.style);
                    saveAndPublish(params).then((response) => {
                        this.pageInfo.id = response;
                        this.pageInfo.status = 2;
                        this.publishSuccess(response);
                        this.$nextTick(() => (this.changed = false));
                    });
                },
            });
        },
        save() {
            if (!this.validate()) {
                return;
            }
            var params = JSON.parse(JSON.stringify(this.pageInfo));
            params.questions.forEach((q) => delete q.questionDefinition);
            params.template = JSON.stringify(params.questions);
            delete params.questions;
            params.style = JSON.stringify(params.style);
            save(params).then((response) => {
                this.pageInfo.id = response;
                if (this.pageInfo.status == 0) {
                    this.pageInfo.status = 1;
                }
                this.$Message.success("轻页面保存成功！");
                this.$nextTick(() => (this.changed = false));
            });
        },
        publishSuccess(id) {
            getPageUrl(id).then((response) => {
                this.$refs.qrcode.innerHTML = "";
                this.modal = true;
                this.pageUrl = response;
                new QRCode("qrcode", {
                    width: 124,
                    height: 124,
                    text: response,
                });
            });
        },
        preview() {
            getPreviewUrl(this.pageInfo.id).then((response) => {
                this.$refs.qrcode2.innerHTML = "";
                this.modal2 = true;
                this.pageUrl2 = response;
                new QRCode("qrcode2", {
                    width: 124,
                    height: 124,
                    text: response,
                });
            });
        },
        pause() {
            this.$Modal.confirm({
                title: "确定对该轻页面进行暂停发布吗？",
                content: "<p>暂停后轻页面将停止收集信息，确定对该轻页图文素材暂停发布吗？</p>",
                onOk: () => {
                    pause(this.pageInfo.id).then(() => {
                        this.$Message.success("轻页面已暂停成功！");
                        this.pageInfo.status = 3;
                    });
                },
            });
        },
        getOfficialAccounts() {
            getOfficialAccountList().then((response) => (this.officialAccounts = response));
        },
        loadSmsAccount() {
            getSmsAccountList().then((response) => {
                this.smsAccountList = response;
            });
        },
        loadLightpages() {
            getList({ status: 2, pageNum: 1, pageSize: 0 }).then((response) => {
                this.lightpages = response.data;
            });
        },
        clickQuestion(question, event) {
            if (
                this.dragingStartOffset.originX != 0 &&
                (event.clientX != this.dragingStartOffset.originX || event.clientY != this.dragingStartOffset.originY)
            ) {
                return;
            }
            this.focusQuestion = null;
            this.focusQuestion = question;
            if (question.questionDefinition.properties.length == 0) {
                this.showProperty = false;
            } else {
                this.showProperty = true;
            }
        },
        removeQuestion(question) {
            var index = this.pageInfo.questions.findIndex((q) => q === question);
            this.$Modal.confirm({
                title: "是否确认删除该组件？",
                onOk: () => {
                    this.pageInfo.questions.splice(index, 1);
                    this.focusQuestion = null;
                    this.showProperty = false;
                    if (question.type === "commit-button") {
                        this.enableCommitButton = false;
                    }
                },
            });
        },
        updateRichText(questionId, richText) {
            this.pageInfo.questions.find((q) => q.id === questionId).content = richText;
        },
        addOption() {
            this.focusQuestion.options.push("新选项");
        },
        removeOption(index) {
            this.focusQuestion.options.splice(index, 1);
        },
        updateTitle(val) {
            this.focusQuestion.title = val;
        },
        updateOptions(val) {
            this.focusQuestion.options = val;
        },
        updateOther(val) {
            this.focusQuestion.other = val;
        },
        updateRequired(val) {
            this.focusQuestion.required = val;
        },
        updateMaxLength(val) {
            this.focusQuestion.maxLength = val;
        },
        updateColor(val) {
            this.focusQuestion.color = val;
        },
        updateBackgroundColor(val) {
            this.focusQuestion.backgroundColor = val;
        },
        updateText(val) {
            this.focusQuestion.text = val;
        },
        updateSmsAccountId(val) {
            this.focusQuestion.smsAccountId = val;
        },
        updateSmsTemplate(val) {
            this.focusQuestion.smsTemplate = val;
        },
        updateParameterKey(val) {
            this.focusQuestion.parameterKey = val;
        },
        updateDisplay(val) {
            this.focusQuestion.display = val;
        },
        updateCommitLogic(val) {
            this.focusQuestion.commitLogicType = val.commitLogicType;
            this.focusQuestion.commitLogicValue = val.commitLogicValue;
        },
        updateImageList(val) {
            this.focusQuestion.imageList = val;
        },
        updateFileUrl(val) {
            this.focusQuestion.fileUrl = val.fileUrl;
            this.focusQuestion.fileName = val.fileName;
        },
        updateMapTitle(val) {
            this.focusQuestion.mapTitle = val;
        },
        updateCity(val) {
            this.focusQuestion.city = val;
        },
        updateLocationInfo(val) {
            this.focusQuestion.locationInfo = val;
        },
        updateTooltip(val) {
            this.focusQuestion.tooltip = val;
        },
        updateMapStyle(val) {
            this.focusQuestion.mapStyle = val;
        },
        mousedown(event) {
            event.preventDefault();

            this.dragingStartOffset = {
                x: 0,
                y: 0,
                originX: 0,
                originY: 0,
            };

            if (this.pageInfo.status === 2) {
                return;
            }

            if (this.pageInfo.questions.findIndex((q) => q.type === "rich-text" && q.editable) >= 0) {
                return;
            }

            this.dragingElement = event.currentTarget;
            if (event.target == event.currentTarget) {
                this.dragingStartOffset.x = event.offsetX;
                this.dragingStartOffset.y = event.offsetY;
            } else {
                this.dragingStartOffset.x = event.pageX - event.currentTarget.getBoundingClientRect().left;
                this.dragingStartOffset.y = event.pageY - event.currentTarget.getBoundingClientRect().top;
            }
            this.dragingStartOffset.originX = event.clientX;
            this.dragingStartOffset.originY = event.clientY;
        },
        mousemove(event) {
            if (!this.dragingElement) {
                return;
            }
            if (!this.dragingWrapper && (event.movementX > 0 || event.movementY > 0)) {
                this.dragingWrapper = document.createElement("div");
                this.dragingWrapper.appendChild(this.dragingElement.firstChild.cloneNode(true));
                this.dragingWrapper.classList.add("drag-wrapper");
                this.dragingWrapper.style.width = this.dragingElement.offsetWidth + "px";
                this.dragingWrapper.style.height = this.dragingElement.offsetHeight + "px";
                document.body.appendChild(this.dragingWrapper);
                this.dragingElement.classList.add("mask");
            }
            if (!this.dragingWrapper) {
                return;
            }
            this.dragingWrapper.style.left = event.clientX - this.dragingStartOffset.x + "px";
            this.dragingWrapper.style.top = event.clientY - this.dragingStartOffset.y + "px";
            let liParent = event.target.closest(".drag-item");
            if (!liParent) {
                return;
            }
            let target = liParent;
            let targetRect = target.getBoundingClientRect();
            let dragingRect = this.dragingElement.getBoundingClientRect();
            if (target && target.animated) {
                return;
            }
            if (this._index(this.dragingElement) < this._index(target)) {
                target.parentNode.insertBefore(this.dragingElement, target.nextSibling);
            } else {
                target.parentNode.insertBefore(this.dragingElement, target);
            }
            this._animate(dragingRect, this.dragingElement);
            this._animate(targetRect, target);
        },
        mouseup() {
            this.dragingElement && this.dragingElement.classList.remove("mask");
            this.dragingElement = null;
            if (!this.dragingWrapper) {
                return;
            }
            this.dragingWrapper.remove();
            this.dragingWrapper = null;

            let questions = [];
            let childrens = this.$refs.previewQuestions.children;
            for (let i = 0; i < childrens.length; i++) {
                let item = this.pageInfo.questions.find((q) => q.id === childrens[i].getAttribute("orderid"));
                questions.push(item);
            }
            this.pageInfo.questions = questions;
        },
        _index(el) {
            var index = 0;

            if (!el || !el.parentNode) {
                return -1;
            }

            while (el && (el = el.previousElementSibling)) {
                index++;
            }

            return index;
        },
        _animate(prevRect, target) {
            var ms = 300;

            if (ms) {
                var currentRect = target.getBoundingClientRect();

                if (prevRect.nodeType === 1) {
                    prevRect = prevRect.getBoundingClientRect();
                }

                this._css(target, "transition", "none");
                this._css(
                    target,
                    "transform",
                    "translate3d(" + (prevRect.left - currentRect.left) + "px," + (prevRect.top - currentRect.top) + "px,0)"
                );

                target.offsetWidth;
                this._css(target, "transition", "all " + ms + "ms");
                this._css(target, "transform", "translate3d(0,0,0)");

                clearTimeout(target.animated);
                target.animated = setTimeout(() => {
                    this._css(target, "transition", "");
                    this._css(target, "transform", "");
                    target.animated = false;
                }, ms);
            }
        },
        _css(el, prop, val) {
            var style = el && el.style;

            if (style) {
                if (val === void 0) {
                    if (document.defaultView && document.defaultView.getComputedStyle) {
                        val = document.defaultView.getComputedStyle(el, "");
                    } else if (el.currentStyle) {
                        val = el.currentStyle;
                    }

                    return prop === void 0 ? val : val[prop];
                } else {
                    if (!(prop in style)) {
                        prop = "-webkit-" + prop;
                    }

                    style[prop] = val + (typeof val === "string" ? "" : "px");
                }
            }
        },
        insertQuestion(question) {
            if (this.pageInfo.status === 2) {
                this.$Message.error("请先暂停发布中的表单再编辑表单控件！");
                return;
            }
            this.showGuidePanel = false;
            let questionItem = {};
            Object.assign(questionItem, JSON.parse(JSON.stringify(question.defaultSetting)), {
                id: question.type + "_" + new Date().getTime(),
                type: question.type,
                questionDefinition: question,
            });
            this.pageInfo.questions.push(questionItem);
            this.$nextTick(() => {
                let scrollTop = this.$refs.formBody.scrollHeight - this.$refs.formBody.clientHeight;
                if (scrollTop > 0) {
                    this.$refs.formBody.scrollTop = scrollTop;
                }
            });
        },
    },
};
</script>
<style lang="less">
.ivu-tabs-tab {
    border: none !important;
    color: #8494a6 !important;
    font-size: 12px !important;
    line-height: 24px;
}

.ivu-tabs-bar {
    margin-bottom: 0;
    border: none !important;
}

.ivu-tabs-tab-active {
    color: #0a2a4c !important;
}

.main-description textarea {
    resize: none;
}

.question-property-text textarea {
    resize: none;
}

.question-item {
    h2 {
        color: #0a2a4c;
        font-size: 14px;
        min-height: 20px;
        line-height: 20px;
        margin: 0 0 12px 0;

        &[required]:after {
            content: "*";
            color: #ff0000;
            margin-left: 5px;
        }
    }
}

.edui-editor {
    z-index: 99 !important;
}

.qrcode-detail {
    h2 {
        font-size: 14px;
        font-weight: 500;
        color: #0a2a4c;
        margin: 0;
        height: 20px;
        line-height: 20px;
    }

    .input {
        margin-top: 12px;
        margin-bottom: 20px;
    }

    .qrcode-img {
        text-align: center;
        margin: 16px auto 0 auto;
        width: 124px;
        height: 124px;
    }
}

.drag-wrapper {
    position: absolute;
    padding: 23px 20px;
    z-index: 999;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    pointer-events: none;
}

.ivu-radio-wrapper {
    height: auto !important;
    min-height: 30px;
    word-break: break-word;
    white-space: pre-wrap;
}

.light-page-title {
    .ivu-input {
        text-align: center;
        min-height: 38px;
        line-height: 32px;
        overflow-y: hidden;
        font-size: 18px;
        resize: none;
        font-weight: bold;
    }
}

.header-carousel-upload {
    .ivu-upload-select {
        width: 100%;
    }
}
</style>
<style lang="less" scoped>
.main-container {
    min-width: 1200px;
}

h2.title {
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    font-weight: bold;
    margin: 20px 0 8px 0;
    color: #0a2a4c;

    &[required]:after {
        content: "*";
        color: #ff0000;
        margin-left: 6px;
        font-weight: bold;
    }
}

h3.title {
    font-size: 13px;
    height: 18px;
    line-height: 18px;
    font-weight: bold;
    margin: 20px 0 8px 0;
    color: #0a2a4c;
}

.panel-wrapper {
    padding: 1px 16px 16px 16px;
    background-color: #fff;
    border-radius: 0 4px 4px 4px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d4dbe1;
    }

    p {
        color: #8494a6;
        font-size: 12px;
        margin: 0;
    }

    .cover-img-upload {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px dashed #d4dbe1;
        cursor: pointer;

        &:hover {
            border-color: @fc-main-color;
        }

        &.has-image {
            border: none;
        }
    }
}

.preview-box {
    margin-top: 20px;

    .mock-tab {
        background-color: #fff;
        height: 32px;
        line-height: 32px;
        width: 70px;
        font-size: 12px;
        color: #0a2a4c;
        padding-left: 11px;
        box-sizing: border-box;
        border-radius: 4px 4px 0px 0px;
    }

    .preview {
        padding: 16px;
        background-color: #fff;
        border-radius: 0 4px 4px 4px;

        h2 {
            font-weight: normal;
            font-size: 16px;
            margin: 0;
            color: #323233;
            max-height: 44px;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            word-break: break-word;
        }

        .detail {
            display: flex;
            margin-top: 16px;

            .grey {
                background-color: #d8d8d8;
            }

            .description {
                margin: 0 10px 0 0;
                flex: 1 1 auto;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                word-break: break-word;
            }

            .cover-img {
                width: 50px;
                height: 50px;
                flex: 0 0 auto;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.questions {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
        width: 50%;
        flex: 0 0 50%;
        box-sizing: border-box;
        border-left: 1px solid #d4dbe1;
        border-top: 1px solid #d4dbe1;
        cursor: pointer;
        user-select: none;
        position: relative;

        &:hover {
            z-index: 2;
            outline: 1px solid #00b2a6;
        }

        &:nth-child(even) {
            border-right: 1px solid #d4dbe1;
        }

        &:nth-last-child(2) {
            border-bottom: 1px solid #d4dbe1;
        }

        &:last-child {
            border-right: 1px solid #d4dbe1;
            border-bottom: 1px solid #d4dbe1;
        }
    }
}

.background-setting {
    display: flex;
    justify-content: space-between;

    .upload-bg {
        border: none;
        outline: none;
        background-color: transparent;
        color: #00b2a6;
    }
}

.fill-setting-item {
    display: flex;
    justify-content: space-between;

    .item-color {
        margin-top: 12px;
    }

    .item-switch {
        margin-top: 18px;
    }
}

.form-setting-item {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    .title {
        font-weight: normal;
        margin: 0;
        height: 32px;
        line-height: 32px;
    }
}

.header-carousel {
    .upload-btn {
        border: 1px dashed #d4dbe1;
        width: 100%;
        cursor: pointer;

        &:hover {
            border-color: @fc-main-color;
        }

        i {
            font-size: 31px;
            width: 32px;
            height: 32px;
            display: block;
            margin: 10px auto 0 auto;
            color: @fc-main-color;
        }

        h2 {
            text-align: center;
            font-size: 14px;
            height: 22px;
            line-height: 22px;
            margin: 10px 0 0 0;
            font-weight: normal;
            color: #395069;
        }

        h3 {
            text-align: center;
            font-size: 12px;
            color: #8494a6;
            margin: 0 0 16px 0;
            font-weight: normal;
            height: 18px;
            line-height: 18px;
        }
    }

    .carousel-image-list {
        list-style: none;
        padding: 0;
        margin-top: 8px;

        li {
            position: relative;
            overflow: hidden;
            height: 28px;
            line-height: 28px;

            &:hover {
                background-color: #f8f8f8;
            }

            .nz-file-text {
                color: #8494a6;
                float: left;
            }

            h3 {
                font-weight: normal;
                margin: 0;
                text-align: left;
                color: #395069;
                position: absolute;
                left: 26px;
                right: 26px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
            }

            .nz-delete {
                color: #8494a6;
                float: right;
                margin-right: 5px;

                &:hover {
                    color: #ed4014;
                }
            }
        }
    }

    .remark {
        display: flex;
        margin-top: 5px;

        i {
            color: @fc-main-color;
            width: 16px;
        }

        p {
            margin: 3px 0 0 10px;
            font-size: 12px;
            color: #8494a6;
            line-height: 18px;
        }
    }
}

.official-account-setting {
    font-size: 0;

    button {
        border: 1px solid #d4dbe1;
        color: #395069;
        font-size: 14px;
        outline: none;
        background-color: transparent;
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
        cursor: pointer;

        &.active {
            border: 1px solid @fc-main-color !important;
            color: @fc-main-color;
        }

        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
            border-left: none;
        }

        &:hover {
            border: 1px solid @fc-main-color !important;
        }
    }
}

.auth-desc {
    padding-left: 14px;
}

.official-account-select {
    display: flex;
    img {
        display: inline-block;
        width: 36px;
        height: 36px;
        margin-right: 10px;
    }

    .select {
        margin-top: 2px;
    }
}

.form-body {
    box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d4dbe1;
    }

    .question-content-wrapper {
        background-size: cover !important;
        padding: 20px 30px;
        box-sizing: border-box;
        min-height: 100%;
    }

    .question-content {
        height: 100%;

        &.image-bg {
            background-color: rgba(255, 255, 255, 0.9);
        }
    }

    .title {
        font-size: 18px;
        color: #0a2a4c;
        text-align: center;
        word-break: break-word;
        padding: 24px 20px;
    }

    .guide-panel {
        height: 69px;
        border: 1px dashed #d4dbe1;
        background-color: #f8f8f8;
        overflow: hidden;
        display: flex;

        img {
            height: 37px;
        }

        h3 {
            height: 67px;
            line-height: 67px;
            font-size: 14px;
            color: #8494a6;
            font-weight: normal;
            margin: 0 136px 0 0;
            text-align: center;
            flex: 1 1 auto;
            padding: 0;
        }
    }
}

.preview-questions {
    list-style: none;
    padding: 1px;

    & > li {
        padding: 23px 20px;
        position: relative;
        cursor: move;
        user-select: none;

        &.mask {
            outline: 1px dashed #d4dbe1;

            &:after {
                position: absolute;
                content: " ";
                width: 100%;
                left: 0;
                height: 100%;
                top: 0;
                background-color: #f5faff;
                z-index: 2;
            }

            .remove,
            .edit {
                display: none !important;
            }
        }

        &:hover {
            background-color: #f5faff;
            outline: 1px dashed #d4dbe1;

            .remove {
                display: block;
            }

            .edit {
                display: block;
            }
        }

        &.focus {
            background-color: #f5faff;
            outline: 1px dashed #d4dbe1;
        }
    }

    .remove {
        display: none;
        position: absolute;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        border: 1px solid #d4dbe1;
        outline: none;
        line-height: 32px;
        top: -16px;
        right: 12px;
        color: #ed4014;
        background-color: #fff;
        cursor: pointer;

        &:hover {
            color: #d4dbe1;
            background-color: #ed4014;
            border-color: #ed4014;
        }
    }

    .edit {
        display: none;
        position: absolute;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        border: 1px solid #d4dbe1;
        outline: none;
        line-height: 32px;
        top: -16px;
        right: 52px;
        color: @fc-main-color;
        background-color: #fff;
        cursor: pointer;

        &:hover {
            color: #d4dbe1;
            background-color: @fc-main-color;
            border-color: @fc-main-color;
        }
    }
}

.property-panel {
    width: 100%;
    position: relative;
    right: -20px;
    margin-top: -20px;
}
</style>