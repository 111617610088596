<template>
    <div class="control-box">
        <div class="icon"><i :class="icon" /></div>
        <h2>{{name}}</h2>
    </div>
</template>
<script>
export default {
    name: "QuestionIcon",
    props: ["icon", "name"]
}
</script>
<style lang="less" scoped>
    .control-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 60px;
        color: #395069;

        &:hover {
            color: @fc-main-color;
        }
    }

    .icon {
        font-size: 16px;
        margin-top: 7px;
    }

    h2 {
        font-weight: 400;
        margin: 0;
        text-align: center;
        font-size: 14px;
        height: 20px;
        line-height: 20px;
    }
</style>