const displayQuestions = {
    "rich-text": {
        type: "rich-text",
        name: "图文编辑",
        icon: "nazaio-iconfont nz-unordered-list",
        properties: [],
        defaultSetting: {
            editable: true,
            content: ''
        },
        component: () => import('@/components/questions/display/RichText')
    },
    "banner": {
        type: "banner",
        name: "Banner",
        icon: "nazaio-iconfont nz-image",
        properties: ["banner"],
        defaultSetting: {
            imageList: []
        },
        component: () => import('@/components/questions/display/Banner')
    },
    "map": {
        type: "map",
        name: "位置信息",
        icon: "nazaio-iconfont nz-analy-area",
        properties: ["map"],
        defaultSetting: {
            mapTitle: "",
            city: "",
            locationInfo: {
                id: "",
                name: "",
                address: "",
                location: ""
            },
            tooltip: "",
            mapStyle: "map"
        },
        component: () => import('@/components/questions/display/Map')
    },
    "display-file": {
        type: "display-file",
        name: "上传资料",
        icon: "nazaio-iconfont nz-upload",
        properties: ["displayFile"],
        defaultSetting: {
            fileUrl: "",
            fileName: ""
        },
        component: () => import('@/components/questions/display/DisplayFile')
    }
};

const normalQuestions = {
    "single-choice": {
        type: "single-choice",
        name: "单选题",
        icon: "ivu-icon ivu-icon-md-radio-button-on",
        properties: ["title", "options", "other", "required"],
        defaultSetting: {
            title: "单选题",
            options: ["选项一", "选项二"],
            other: false,
            required: true
        },
        component: () => import('@/components/questions/normal/SingleChoice')
    },
    "multiple-choice": {
        type: "multiple-choice",
        name: "多选题",
        icon: "ivu-icon ivu-icon-md-checkbox-outline",
        properties: ["title", "options", "other", "required"],
        defaultSetting: {
            title: "多选题",
            options: ["选项一", "选项二"],
            other: false,
            required: true
        },
        component: () => import('@/components/questions/normal/MultipleChoice')
    },
    "single-line-text": {
        type: "single-line-text",
        name: "单行文本题",
        icon: "ivu-icon ivu-icon-md-create",
        properties: ["title", "maxLength", "required"],
        defaultSetting: {
            title: "单行文本题",
            maxLength: 0,
            required: true
        },
        component: () => import('@/components/questions/normal/SingleLineText')
    },
    "multiple-line-text": {
        type: "multiple-line-text",
        name: "多行文本题",
        icon: "nazaio-iconfont nz-file-text",
        properties: ["title", "maxLength", "required"],
        defaultSetting: {
            title: "多行文本题",
            maxLength: 0,
            required: true
        },
        component: () => import('@/components/questions/normal/MultipleLineText')
    },
    "dropdown-choice": {
        type: "dropdown-choice",
        name: "下拉选择题",
        icon: "nazaio-iconfont nz-caret-down",
        properties: ["title", "options", "required"],
        defaultSetting: {
            title: "下拉选择题",
            options: ["选项一", "选项二"],
            required: true
        },
        component: () => import('@/components/questions/normal/DropdownChoice')
    },
    "image-upload": {
        type: "image-upload",
        name: "附件上传",
        icon: "nazaio-iconfont nz-cloud-upload",
        properties: ["title", "required"],
        defaultSetting: {
            title: "附件上传",
            required: false
        },
        component: () => import('@/components/questions/normal/ImageUpload')
    },
    "url-parameter": {
        type: "url-parameter",
        name: "URL参数",
        icon: "nazaio-iconfont nz-url",
        properties: ["title", "parameterKey", "display"],
        defaultSetting: {
            title: "URL参数",
            parameterKey: "key",
            display: true
        },
        component: () => import('@/components/questions/normal/UrlParameter')
    }
};

const baseQuestions = {
    "mobile" : {
        type: "mobile",
        name: "手机号",
        icon: "nazaio-iconfont nz-mobile",
        properties: ["title", "smsValidate", "required"],
        defaultSetting: {
            title: "手机号",
            required: true,
            regex: "^1\\d{10}$",
            smsAccountId: 0
        },
        component: () => import('@/components/questions/base/Mobile')
    },
    "email": {
        type: "email",
        name: "邮箱",
        icon: "nazaio-iconfont nz-mail",
        properties: ["title", "required"],
        defaultSetting: {
            title: "邮箱",
            required: true,
            regex: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{1,10})+$"
        },
        component: () => import('@/components/questions/base/Email')
    }
};

const otherQuestions = {
    "commit-button": {
        type: "commit-button",
        name: "按钮",
        icon: "nazaio-iconfont nz-dashboard-eattr",
        properties: ["text", "color", "backgroundColor", "commitLogic"],
        defaultSetting: {
            text: "提交",
            color: "#fff",
            backgroundColor: "#00B2A6",
            commitLogicType: "message",
            commitLogicValue: "提交成功！"
        },
        component: () => import('@/components/questions/normal/CommitButton')
    }
};

export {displayQuestions, normalQuestions, baseQuestions, otherQuestions};